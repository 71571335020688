import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Canvey Island",
  "priceBand": 1,
  "title": "Children’s entertainers in Canvey Island",
  "description": "Canvey Island is a fantastic place to live and want to throw a children’s party. With a number of nicely finished and affortable local halls, party planning can be a breeze.",
  "keywords": ["Canvey Island"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Planning a children’s party in Canvey Island`}</h1>
    <h2>{`Top local halls`}</h2>
    <ul>
      <li parentName="ul">{`Hall one`}</li>
      <li parentName="ul">{`Hall two`}</li>
      <li parentName="ul">{`Hall three`}</li>
    </ul>
    <p><a parentName="p" {...{
        "href": "/locations/"
      }}>{`View all party venues in Essex`}</a></p>
    <h2>{`Where to buy party supplies`}</h2>
    <h2>{`School holidays`}</h2>
    <h2>{`Fees for the area`}</h2>
    <p>{`Professor Rainbows is based on Canvey Island so hiring an entertainer we will be a very cost effective as we will not need to charge any additional travel time or fuel fees.`}</p>
    <p><contact-cta></contact-cta></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      